import React from 'react';

interface Props {
  telephone: string;
  email: string;
  siteUrl: string;
}

const Impressum: React.FC<Props> = ({ siteUrl, email, telephone }) => {
  return (
    <div className={`Impressum`}>
      <p>
        Angaben gemäß § 5 TMG und § 55 RStV. Diese Website unter der Domain{' '}
        {siteUrl} wird angeboten von:
      </p>
      <div className="Impressum__juristic">
        <div>Rathika Soosaithasan</div>
        <div>Horkheimer Straße 29</div>
        <div>DE-74081 Heilbronn</div>
        <div>E-Mail: {email}</div>

        <div>Phone: {telephone}</div>
      </div>
      <h3>Haftung für Inhalte</h3>
      <p>
        Wir sind bemüht, die Informationen auf unserer Website aktuell zu
        halten, übernehmen jedoch keinerlei Haftung für die bereitgestellten
        Inhalte. Gemäß §7 Abs. Nach § 1 TMG sind wir als Diensteanbieter jedoch
        nur für eigene Inhalte auf diesen Seiten verantwortlich.
      </p>
      <p>
        Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
        verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
        überwachen. Bei Bekanntwerden von Rechtsverletzungen werden wir
        derartige Inhalte umgehend entfernen. Unsere Haftung beginnt in einem
        solchen Fall mit dem Zeitpunkt, zu dem wir Kenntnis von der jeweiligen
        Rechtsverletzung erhalten. Haftung für Links
      </p>
      <p>
        Unsere Website enthält Links zu Websites Dritter. Wir haben keinerlei
        Einfluss auf die Informationen auf diesen Websites und übernehmen keine
        Gewähr für deren Richtigkeit. Für den Inhalt der verlinkten Seiten sind
        ausschließlich deren Betreiber verantwortlich. Zum Zeitpunkt der
        Verlinkung von Websites Dritter auf unsere Websites haben wir keinerlei
        Anhaltspunkte für einen möglichen Rechtsverstoß gefunden. Bei
        Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
        entfernen.
      </p>

      <h3>Urheberrechte</h3>

      <p>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Vermarktung solcher
        Materialien außerhalb der Grenzen des Urheberrechtes bedürfen der
        vorherigen schriftlichen Zustimmung des jeweiligen Autors bzw.
        Erstellers.
      </p>
      <h3>Entwicklung</h3>
      <p>
        Die Webseite wurde realisiert durch die{' '}
        <a href="https://soosap.co">soosap GmbH</a>, Theaterstrasse 25a, CH-8400
        Winterthur.
      </p>
    </div>
  );
};

export default Impressum;
